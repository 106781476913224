<template>
  <v-list
    v-auto-animate
    class="d-flex flex-col flex-1 py-0"
    color="transparent"
    dense
    min-width="220"
    :height="fillHeight ? '100%' : 'auto'"
    tile
  >
    <template v-for="(item, key) in items">
      <slot :item="item" :name="`item.${key}`">
        <v-list-item
          v-if="item.value || item.pined"
          :key="`item-${key}`"
          class="text-right"
          style="min-height: auto"
        >
          <v-list-item-content :class="sContentCss">
            <slot :name="`item.${key}.label`">
              <v-list-item-subtitle v-text="$t(item.label)" />
            </slot>
          </v-list-item-content>
          <v-list-item-content :class="sContentCss">
            <v-list-item-title>
              <slot :name="`item.${key}.value`">
                <animated-number
                  v-if="item.animated"
                  :formatValue="formatValue"
                  :value="item.value"
                />
                <v-list-item-title v-else v-text="formatValue(item.value)" />
              </slot>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </slot>
    </template>
  </v-list>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import type { Callback } from "@/types/utils";
import AnimatedNumber from "animated-number-vue";

export interface ListAmountItem {
  label?: string;
  value: number;
  pined?: boolean;
  animated?: boolean;
}

@Component({
  components: { AnimatedNumber },
})
export default class ListAmounts extends Vue {
  @Prop(Object) readonly items!: Record<string, ListAmountItem>;
  @Prop(Function) readonly formatValue!: Callback;
  @Prop(Boolean) readonly dense!: boolean;
  @Prop(Boolean) readonly fillHeight!: boolean;

  get sContentCss() {
    return {
      "py-0": this.dense,
    };
  }
}
</script>
